import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { initAmplitude, logCtaClickEvent, logAmplitudeCompletedCampaignEvent } from '../amplitude';
import { Campaign, CampaignPreviewCard} from 'dabbl-react-components';
import {API_URL, RETAILER_NAME} from "../config";
import favicon32 from '../assets/favicon-32x32.png'
import {
    Alert,
    Media,
    Container,
    Row,
    Col
} from 'reactstrap';

function CampaignPage(props) {
    console.log('Starting the CampaignPage...');
    const [campaign, setCampaign] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isVisibleAlert, setIsVisibleAlert] = useState(true);
    const [isPreviewShown, setIsPreviewShown] = useState(false);
    const [requestError, setRequestError] = useState('');
    const [answers, setAnswers] = useState([]);

    console.log('Starting the CampaignPage2...');
    console.log(props)
    console.log(props.match.params)
    console.log(props.match.params.token)
    console.log(RETAILER_NAME)
    const salesNumber = props.match.params.token
    useEffect(() => {

        console.log('Starting the CampaignPage - useEffect');
        initAmplitude();

        if (!salesNumber) { //EXPERIENCE_SALES_NUMBER
            setRequestError("No experience specified to load")
        }

        // axios.get(`${API_URL}/pub/v1/campaignPreview/${EXPERIENCE_TOKEN}`)
        axios.get(`${API_URL}/pub/v1/campaign/${salesNumber}/feed`)
            .then(result => {
                console.log(result)
                if (result && result.status === 200 && !result.data.error) {
                    setCampaign(result.data);
                    setIsLoading(false)
                } else {
                    setRequestError('Error occurred on backend side.');
                }
            }).catch(e => {
            setRequestError(e.message);
        });
        window.onpopstate = (e) => {
            if (!isPreviewShown){ return }
            setIsPreviewShown(false)
        }

        setTimeout(()=>{setIsPreviewShown(true)}, 2500)
    }, [])

    function handlePreviewClick() {
        setIsPreviewShown(true)
    }

    function handleAllEngagementsAnswered(campaignId) {
        let timeSpent = 0;
        answers.forEach((e) => {
            if (e.timeSpent
                && !isNaN(e.timeSpent)
                && e.timeSpent > 0)
            {
                timeSpent += e.timeSpent;
            }
        });

        console.debug("timeSpent = " + timeSpent)
        const options = {
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Accept': 'application/json'
            }
        };
        const data = {
            "timeSpent": timeSpent,
            "responses": answers
        }
        axios.post(`${API_URL}/pub/v1/hypr/campaignCompletion`, data, options)
            .then(result => {
                console.log(result)
                if (result && result.status === 200 && !result.data.error) {
                    console.log('answers were uploaded.')
                    logAmplitudeCompletedCampaignEvent(campaign.name, campaign.totalValue)
                } else {
                    setRequestError('Error occurred on backend side.');
                }
            }).catch(e => {
            setRequestError(e.message);
        });
    }

    function handleEngagementAnswered(engagementIndex, answer, duration){
        console.log(`Campaign: handleEngagementAnswered, index = ${engagementIndex}, answer = ${answer}, duration =${duration}`)
        const engagement = campaign.engagements[engagementIndex];
        answers.push({
            answer: answer,
            campaignId: campaign.id,
            engagementId: engagement.id,
            timeSpent: duration
        })
        setAnswers(answers)
    }


    function onConclusionLinkClick(url){
        if (campaign && campaign.brand) {
            logCtaClickEvent(campaign.name, campaign.id, campaign.brand.name, url)
        }
        // window.TJ_API && window.TJ_API.click();
        // window.TJ_API && window.TJ_API.gameplayFinished();
    }
    if (requestError) {
        return <div className='vertical-center' style={{height: "100%"}}>{requestError}</div>
    }

    if (isLoading) {
        return <div className='App-message'>Loading...</div>
    }

    return (<div className='vertical-center' style={{height: "100%"}}>
            { isVisibleAlert &&
            <Container className='ExperienceAlert'>
            <Row>
                <Col>
                    <Alert color="warning" isOpen={isVisibleAlert} toggle={() => (setIsVisibleAlert(false))} style={{width: '100%', textAlign: 'center'}}>
                        {/*<Media>*/}
                        {/*    <Media left middle object src={favicon32} style={{maxHeight:'32px', maxWidth:'32px'}} alt='Dabbl' />*/}
                        {/*    <Media body></Media>*/}
                        {/*</Media>*/}
                        Tap to Proceed!
                    </Alert>
                </Col>
            </Row>
            </Container>
            }
            {isPreviewShown ? (
                <div className="PreviewCampaign">
                    <Campaign
                        campaign={campaign}
                        retailerName={RETAILER_NAME}
                        reward={window.TJ_API && window.TJ_API.adInfo && window.TJ_API.adInfo.reward}
                        currencyName={window.TJ_API && window.TJ_API.adInfo && window.TJ_API.adInfo.currencyName}
                        autoPlayVideo={true}
                        hideTotalValue={true}
                        skipCtaLinkOpen={false}
                        showToast={false}
                        usePoints={true}
                        hideToastCopy={false}
                        onCloseCampaign={() => {
                            // window.TJ_API && window.TJ_API.gameplayFinished();
                            // backToPreview();
                        }}
                        onDoneCampaign={() => {
                            // window.TJ_API && window.TJ_API.gameplayFinished();
                        }}
                        onEngagementAnswered={(engagementIndex, answer, duration) => handleEngagementAnswered(engagementIndex, answer, duration) }
                        onAllEngagementsAnswered={()=> {
                            // window.TJ_API && window.TJ_API.objectiveComplete();
                            handleAllEngagementsAnswered()
                        }}
                        onConclusionLinkClick={(url)=>onConclusionLinkClick(url)}
                    />
                </div>
            ) : (
                <div className="PreviewCampaign_PreviewCard">
                    <Container>
                        <Row>
                            <Col xs={12}
                            sm={{size: 10, offset: 1}}
                            md={{size: 8,  offset: 2}}
                                lg={{size: 8, offset: 2}}
                            xl={{size: 6, offset: 3}}
                            >
                    <CampaignPreviewCard
                        brandName={campaign.brand.name}
                        retailerName={RETAILER_NAME}
                        totalValue={campaign.totalValue}
                        imgSrc={campaign.feedimageurl}
                        isNewDesign={true}
                        usePoints={true}
                        text={campaign.feedtext}
                        textSecondary={`from ${campaign.brand.name}`}
                        onClick={() => handlePreviewClick()}
                    />
                            </Col></Row></Container>
                </div>
            )
            }
        </div>
    );
}

export default CampaignPage;