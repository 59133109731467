import React, {useEffect, useState} from 'react';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";
import {API_URL} from './config'
import axios from 'axios';
import './App.css';
import CampaignPage from './pages/CampaignPage'


function App(props) {
  console.log('Starting the app...');
  const [isLandscapeMode, setIsLandscapeMode] = useState(window.innerHeight < window.innerWidth);
  useEffect(() => {
    console.log('Starting the app - useEffect');
    if (window.TJ_API) {
      window.TJ_API.setPlayableAPI({
        skipAd: function() { /* go to the end card */
          console.log("skipAd triggered")
        }
      });
    }
    axios.defaults.baseURL = API_URL;
    axios.defaults.headers.post['Content-Type'] = 'application/json; charset=UTF-8';
    axios.defaults.headers.post['Accept'] = 'application/json';
    axios.interceptors.response.use(function (response) {
      return response;
    }, function (error) {
      if (error.response && 401 === error.response.status) {
        console.log('Error 401')
      } else {
        return Promise.reject(error);
      }
    });
    const resizeHandler = event => {
      setIsLandscapeMode(window.innerHeight < window.innerWidth)
    };

    window.addEventListener("resize", resizeHandler, false);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };

    // const orientationHandler = event => {
    //   setIsLandscapeMode(!isLandscapeMode)
    // };
    // // Listen for orientation changes
    // window.addEventListener("orientationchange", orientationHandler, false);
    // return () => {
    //   window.removeEventListener('orientationchange', orientationHandler);
    // };
  });
  if (isLandscapeMode) {
    return <div className='App-message'>Please rotate to view in portrait mode</div>
  }

  return ( <Router>
        <div>
          <Switch>
            <Route path="/:token" component={CampaignPage}/>
          </Switch>
        </div>
      </Router>
  )
}

export default App;