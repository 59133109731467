import amplitude from 'amplitude-js/amplitude';
import {AMPLITUDE_KEY, APP_VERSION} from './config'

export const initAmplitude = () => {
    amplitude.getInstance().init(AMPLITUDE_KEY, null, {
        // logLevel: 'INFO',
        platform: 'Web - Tapjoy'
    });
    amplitude.getInstance().setVersionName(APP_VERSION);
};

export const logAmplitudeCompletedCampaignEvent = (name, value) => {
    amplitude.getInstance().logEvent("Completed Campaign", {"CampaignName": name, "ConsumerValue": value});
};

export const logCtaClickEvent = (name, id, brandName, url) => {
    amplitude.getInstance().logEvent("Conclusion Link Click",  {"Campaign Name": name, "Campaign Id": id, "Brand Name": brandName, "Actual URL": url});
};

export const getAmplitudeDeviceId = () => {
    return amplitude.getInstance().options.deviceId;
};
